<template>
  <div class="content sc-width">
    <div class="main-title">
      Conferences
    </div>
    <div class="pd-20" v-if="modType === 'card'">
      <div class="mb-20 live-title">Live Conferences</div>
      <div class="divided"></div>
      <div class="box">
        <Spin v-if="live.loading" fix></Spin>

        <div class="empty" v-if="live.list.length === 0">
          No Data
        </div>

        <div v-else>
          <Row type="flex" justify="start" :gutter="16">
            <i-col
              class="item"
              :key="item.id"
              v-for="item in live.list"
              span="8"
            >
              <router-link :to="`/conference/${item.id}`">
                <Card>
                  <div class="head">
                    <div class="img">
                      <img :src="item.avatar.url" />
                    </div>
                    <div class="head-right">
                      <div class="title">{{ item.title | nameFilter }}</div>
                      <div class="head-divided"></div>
                      <!-- <div class="sub-title">
                        {{
                          item.effective_attendee_count +
                            item.effective_exhibitor_count
                        }} companies attending
                      </div> -->
                      <div class="sub-title">
                        CONNEXIMA {{ item.start_date }}
                      </div>
                    </div>

                    <div class="flex column align-end">
                      <div class="flex justify-end">
                        <div class="tag">LIVE</div>
                      </div>
                      <!-- <div class="date">{{ item.start_date }}</div> -->
                    </div>
                  </div>
                  <div class=" live-desc">
                    <div class="description" v-html="item.description"></div>
                  </div>
                </Card>
              </router-link>
            </i-col>
          </Row>
          <div class="page">
            <Page
              :page-size="live.limit"
              :page-size-opts="[3, 9, 15, 18]"
              :total="live.total"
              show-sizer
              show-elevator
              show-total
              @on-change="pageChange($event, 'live')"
              @on-page-size-change="pageSizeChange($event, 'live')"
            />
          </div>
        </div>
      </div>

      <h2 class="mb-20">Upcoming Conferences</h2>

      <div class="box">
        <Spin v-if="upcoming.loading" fix></Spin>

        <div class="empty" v-if="upcoming.list.length === 0">
          No Data
        </div>

        <div v-else>
          <Row type="flex" justify="start" :gutter="16">
            <i-col
              class="item"
              :key="item.id"
              v-for="item in upcoming.list"
              span="8"
            >
              <router-link :to="`/conference/${item.id}`">
                <Card>
                  <div class="head">
                    <div>
                      <div class="title">{{ item.title | nameFilter }}</div>
                      <div class="sub-title">{{ item.host_name }}</div>
                    </div>

                    <div class="flex column align-end">
                      <div class="flex justify-end">
                        <div class="not-attending">
                          <div v-if="item.is_attending === null">
                            Not Attending
                          </div>
                          <div
                            v-if="item.is_attending"
                            :style="{
                              color: item.is_attending.status_desc.color
                            }"
                          >
                            {{ item.is_attending.status_desc.name }}
                          </div>
                        </div>
                      </div>
                      <div class="date">{{ item.start_date }}</div>
                    </div>
                  </div>
                  <div class="description" v-html="item.description"></div>
                </Card>
              </router-link>
            </i-col>
          </Row>
          <div class="page">
            <Page
              :page-size="upcoming.limit"
              :page-size-opts="[3, 9, 15, 18]"
              :total="upcoming.total"
              show-sizer
              show-elevator
              show-total
              @on-change="pageChange($event, 'upcoming')"
              @on-page-size-change="pageSizeChange($event, 'upcoming')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="lists" v-if="modType === 'list'">
      <div class="tabler-wrap">
        <h3>Live Conferences</h3>
        <Table
          :columns="conferenceCol"
          :data="live.list"
          :loading="live.loading"
        >
          <template slot-scope="{ row }" slot="title">
            <router-link :to="`/conference/${row.id}`" style="color:#f57c00">{{
              row.title
            }}</router-link>
          </template>
          <template slot="live">
            <span class="tag">LIVE</span>
          </template>
          <template slot-scope="{ row }" slot="description">
            <div v-html="$options.filters.descFilter(row.description)"></div>
          </template>
        </Table>
        <div class="page" v-if="live.total">
          <Page
            :page-size="live.limit"
            :page-size-opts="[3, 9, 15, 18]"
            :total="live.total"
            show-sizer
            show-elevator
            show-total
            @on-change="pageChange($event, 'live')"
            @on-page-size-change="pageSizeChange($event, 'live')"
          />
        </div>
      </div>

      <div class="tabler-wrap" style="margin-top:20px">
        <h3>Upcoming Conferences</h3>
        <Table
          :columns="conferenceCol"
          :data="upcoming.list"
          :loading="upcoming.loading"
        >
          <template slot-scope="{ row }" slot="title">
            <router-link :to="`/conference/${row.id}`" style="color:#f57c00">{{
              row.title
            }}</router-link>
          </template>
          <template slot-scope="{ row }" slot="live">
            {{ row.start_date }} to {{ row.end_date }}
          </template>
          <template slot-scope="{ row }" slot="count">
            {{ row.effective_attendee_count > 9999 ? "**" : "**" }}
          </template>
          <template slot-scope="{ row }" slot="description">
            <div v-html="$options.filters.descFilter(row.description)"></div>
          </template>
        </Table>
        <div class="page" v-if="upcoming.total">
          <Page
            :page-size="upcoming.limit"
            :total="upcoming.total"
            show-elevator
            show-total
            @on-change="pageChange($event, 'upcoming')"
            @on-page-size-change="pageSizeChange($event, 'upcoming')"
          />
        </div>
      </div>
    </div>
    <!--    <Ads adsnum="1"></Ads>-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
import moment from "moment";
// import Ads from "@/components/ads";
const { _queryConferenceList } = api;

export default {
  components: {
    // Ads
  },
  data() {
    return {
      modType: "card",
      filter: {
        search: ""
      },
      live: {
        page: 1,
        limit: 9, // must in 5-20
        total: 0,
        list: [],
        loading: false
      },
      upcoming: {
        page: 1,
        limit: 9, // must in 5-20
        total: 0,
        list: [],
        loading: false
      },
      conferenceCol: [
        {
          title: "Name Of Conference",
          slot: "title",
          ellipsis: true
        },
        {
          title: "Host",
          key: "host_name",
          width: "150px",
          ellipsis: true
        },
        {
          title: "Dates",
          slot: "live",
          align: "center"
        },
        // {
        //   title: "Attending",
        //   key: "effective_attendee_count",
        //   width: "150px",
        //   align: "center"
        // },
        {
          title: "Description",
          slot: "description"
          // render: (h, params) => {
          //   var descShort = "";
          //   var desc = params.row.description;
          //   if (desc.length > 116) descShort = desc.substring(0, 116) + "...";
          //   else descShort = desc;
          //   return h("div", descShort);
          // }
        }
      ],
      adsLists: []
    };
  },

  computed: {
    ...mapState("user", ["token"])
  },
  filters: {
    nameFilter(val) {
      if (val.length > 20) return val.substring(0, 20) + "...";
      else return val;
    },
    descFilter(val) {
      if (val.length > 80) return val.substring(0, 80) + "...";
      else return val;
    }
  },
  created() {
    this.queryConferenceList({
      state: "live"
    });
    this.queryConferenceList({
      state: "upcoming"
    });
  },

  methods: {
    clearSearch() {
      ["live", "upcoming"].forEach(state => {
        this[state].page = 1;
        this.queryConferenceList({
          state
        });
      });
    },

    handleSearch() {
      ["live", "upcoming"].forEach(state => {
        this[state].page = 1;
        this.queryConferenceList({
          ...this.filter,
          state
        });
      });
    },

    pageChange(page, state) {
      this[state].page = page;
      this.queryConferenceList({
        state
      });
    },

    pageSizeChange(limit, state) {
      this[state].limit = limit;
      this.queryConferenceList({
        state
      });
    },

    queryConferenceList(obj) {
      this[obj.state].loading = true;

      _queryConferenceList({
        ...obj,
        page: this[obj.state].page,
        limit: this[obj.state].limit
      })
        .then(response => {
          this[obj.state].loading = false;
          const { data } = response;
          data.data.forEach(item => {
            item.start_date = moment
              .utc(data.start_date)
              .local()
              .format("YYYY-MM-DD");
            item.end_date = moment
              .utc(data.end_date)
              .local()
              .format("YYYY-MM-DD");
          });
          this[obj.state].list = data.data;
          this[obj.state].total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this[obj.state].loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.advImg {
  margin: 20px auto;
  width: 80%;
  min-height: 100px;
  border: 1px solid #f2f2f2;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}
.content {
  margin: 215px auto 0 auto;
  box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;
}
.main-title {
  height: 90px;
  background: linear-gradient(90deg, #a25d72, #2d3b81);
  opacity: 0.9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 28px;

  font-weight: 700;
  color: #ffffff;
  line-height: 18px;
  padding: 35px 0 0 30px;

  position: relative;
  border-bottom: 1px solid #ccc;
  .type-wrap {
    position: absolute;
    top: 22px;
    right: 20px;
    color: #ffca94;
    font-size: 20px;
    cursor: pointer;
    /deep/ .ivu-icon-ios-list {
      position: relative;
      top: 4px;
      font-size: 32px;
      font-weight: bold;
    }
    .cur {
      color: @primary-color;
    }
  }
}
.pd-20 {
  // max-width: 1230px;
  min-width: 1000px;
  margin: auto;
  margin-bottom: 20px;
  background-color: #f5f7f9;
  padding: 0 20px;
  .live-title {
    font-size: 24px;

    font-weight: 500;
    color: #323538;
    line-height: 18px;
    padding: 40px 0 0 30px;
  }
  .divided {
    width: 1440px;
    height: 1px;
    background: #999999;
    margin-bottom: 30px;
  }
  .mb-20 {
    /*color: #333;*/
    /*font-size: 16px;*/
    /*margin-top: 20px;*/
    /*margin-bottom: 0;*/
    /*padding: 20px;*/
    /*background-color: #fff;*/
  }
  .box {
    padding: 0 20px;
    background-color: #fff;
  }
}
.lists {
  // max-width: 1230px;
  min-width: 1000px;
  margin: auto;
  padding: 20px;
  .tabler-wrap {
    background-color: #fff;
  }
  h3 {
    color: #333;
    padding: 10px 15px;
    border: 1px solid #dfdfdf;
    border-bottom: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .ivu-table-wrapper {
    border: 1px solid #dfdfdf;
    /deep/ thead {
      th {
        color: #aaa;
        background-color: #fff;
      }
    }
    /deep/ .ivu-table-overflowX {
      overflow-x: hidden;
    }
    /deep/ .ivu-table-tip {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }

  .tag {
    background: tomato;
    color: #fff;
    padding: 0 4px;
    border-radius: 4px;
    display: inline-flex;
    font-size: 12px;
  }
}
.search-wrap {
  padding: 5px 0 15px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 50px;
  height: 35px;
  overflow: hidden;
  margin: 10px 20px 0;
  background-color: #fff;
  .showing {
    line-height: 35px;
    flex: 1;
  }
  .input-box {
    position: relative;
    width: 350px;
    height: 100%;
    background: #fff;
    line-height: initial;
    padding-left: 40px;
    padding-right: 20px;
    border: 1px solid #dfdfdf;
    border-right: none;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    .search-icon {
      position: absolute;
      top: 7px;
      left: 10px;
      font-size: 20px;
      color: #888;
    }
    input {
      width: 100%;
      height: 100%;
      outline: none;
      background: transparent;
      border: none;
      color: #444;
      caret-color: #888;

      &::-webkit-input-placeholder {
        color: #bdcada;
      }
      &:-moz-placeholder {
        color: #bdcada;
      }
      &::-moz-placeholder {
        color: #bdcada;
      }
      &:-ms-input-placeholder {
        color: #bdcada;
      }
    }
  }
  .btn {
    cursor: pointer;
    background: #fff;
    color: @primary-color;
    border: 1px solid @primary-color;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-self: center;
    line-height: initial;
    height: 100%;
  }
  .clearbtn {
    margin-left: 10px;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 20px;
  }
  .type-wrap {
    position: absolute;
    right: 20px;
    color: #ffca94;
    font-size: 20px;
    cursor: pointer;
    /deep/ .ivu-icon-ios-list {
      position: relative;
      top: 3px;
      font-size: 32px;
      font-weight: bold;
    }
    .cur {
      color: @primary-color;
    }
  }
}

.box {
  min-height: 300px;
  position: relative;
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item {
    margin-bottom: 20px;
    width: 480px;
    height: 184px;
    .head {
      display: flex;
      justify-content: space-between;
      .img {
        img {
          height: 80px;
          width: 120px;
        }
      }
      .head-right {
        margin-left: 20px;
      }
      .title {
        font-size: 16px;
        color: #000;
        font-weight: bold;
        margin-bottom: 14px;
      }
      .head-divided {
        width: 270px;
        height: 1px;
        background: #999999;
        margin-bottom: 13px;
      }
      .sub-title {
        margin-top: 4px;
        font-size: 12px;

        font-weight: 400;
        color: #999999;
        line-height: 18px;
      }
      .justify-end {
        position: relative;
        right: 20px;
      }
      .tag {
        width: 40px;
        height: 18px;
        background: linear-gradient(90deg, #f5a210, #ef8126);
        border-radius: 3px;
        font-size: 10px;

        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
        padding-left: 7px;
      }
      .date {
        margin-top: 10px;
        font-size: 12px;
        color: #666;
      }
      .not-attending {
        color: #666;
      }
      .attending {
        color: @primary-color;
      }
    }
    .description {
      margin-top: 20px;
      color: #999;
      height: 5em;
      overflow: hidden;
      word-break: break-all;
    }
    .live-desc {
      font-size: 16px;

      font-weight: 400;
      color: #ef7f21;
      line-height: 18px;
      /*max-width: 260px;*/
      max-height: 75px;
      overflow: hidden;
    }
  }
}

.page {
  display: flex;
  justify-content: flex-end;
  padding: 20px 15px;
}
.lists .page {
  border: 1px solid #dfdfdf;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.ivu-card {
  height: 100%;
}
</style>
